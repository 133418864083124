import React from 'react';
import SocialMedia from 'components/Shared/SocialMedia';
import NavItems from 'components/Shared/NavItems';
import { animateScrollTo } from 'utils/animateScrollTo';

const SideNav = ({ toggleSideNav, isSideNavOpen, currentElement }) => {
	const handleSideNavItemClick = event => {
		// event.persist();
		setTimeout(() => {
			toggleSideNav();
			// animateScrollTo(event);
		}, 500);
	};

	return (
		<aside className={`d-xs-block d-lg-none ${isSideNavOpen ? 'mobile-menu-open' : ''}`}>
			<nav onClick={event => handleSideNavItemClick(event)} className={`row m-0 mobile-menu`}>
				<NavItems currentElement={currentElement} sideNav />
				<SocialMedia alignHorizontally />
			</nav>
		</aside>
	);
};

export default SideNav;
