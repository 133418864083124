import React from 'react';
import skillsDistributionChart from 'utils/skillsDistributionChart';
import SectionHeading from 'templates/SectionHeading';

class About extends React.PureComponent {
	componentDidMount = () => {
		skillsDistributionChart();
	};

	progressBar = () => (
		<div className="list-group-item">
			<div className="text-medium">JavaScript</div>
			<div className="progress progress-success">
				<div
					className="progress-bar progress-bar-success"
					role="progressbar"
					aria-valuenow="90"
					aria-valuemin="0"
					aria-valuemax="100"
					style={{ width: '90%' }}
				/>
			</div>
		</div>
	);

	render() {
		const subHeading =
			"The main area of my expertise is full stack JavaScript development. Currently, I am using React for the frontend and I'm compiling the code in V8 for backend.";
		return (
			<section id="skills" className="py-5">
				<div className="container">
					<SectionHeading title="Skills" subHeading={subHeading} />
					<div className="row text-left" id="skills-and-focus-graphs">
						<div className="col-md-8">
							<h4 className="pb-2 mt-4 mb-3 border-bottom">Skills Distribution</h4>
							<div id="skills-distribution-chart" />
						</div>
						<div className="col-md-4">
							<h4 className="pb-2 mt-4 mb-3 border-bottom">Focus</h4>
							<div className="list-group list-group-striped push-top">
								<div className="list-group-item">
									<div className="text-medium">JavaScript</div>
									<div className="progress">
										<div
											className="progress-bar progress-bar-striped bg-success"
											role="progressbar"
											aria-valuenow="90"
											aria-valuemin="0"
											aria-valuemax="100"
											style={{ width: '90%' }}
										/>
									</div>
								</div>
								<div className="list-group-item">
									<div className="text-medium">ReactJS</div>
									<div className="progress">
										<div
											className="progress-bar progress-bar-striped bg-success"
											role="progressbar"
											aria-valuenow="88"
											aria-valuemin="0"
											aria-valuemax="100"
											style={{ width: '88%' }}
										/>
									</div>
								</div>
								<div className="list-group-item">
									<div className="text-medium">Node.js</div>
									<div className="progress">
										<div
											className="progress-bar progress-bar-striped bg-info"
											role="progressbar"
											aria-valuenow="80"
											aria-valuemin="0"
											aria-valuemax="100"
											style={{ width: '80%' }}
										/>
									</div>
								</div>
								<div className="list-group-item">
									<div className="text-medium">Functional Programming</div>
									<div className="progress">
										<div
											className="progress-bar progress-bar-striped bg-info"
											role="progressbar"
											aria-valuenow="76"
											aria-valuemin="0"
											aria-valuemax="100"
											style={{ width: '76%' }}
										/>
									</div>
								</div>
								<div className="list-group-item">
									<div className="text-medium">PostgreSQL</div>
									<div className="progress">
										<div
											className="progress-bar progress-bar-striped bg-warning"
											role="progressbar"
											aria-valuenow="74"
											aria-valuemin="0"
											aria-valuemax="100"
											style={{ width: '74%' }}
										/>
									</div>
								</div>
								<div className="list-group-item">
									<div className="text-medium">Python</div>
									<div className="progress">
										<div
											className="progress-bar progress-bar-striped bg-danger"
											role="progressbar"
											aria-valuenow="65"
											aria-valuemin="0"
											aria-valuemax="100"
											style={{ width: '65%' }}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default About;
