import React from 'react';

export default function SectionHeading({ title, subHeading }) {
	const subHeadings =
		subHeading ||
		'Jack of all UX trades, master of a growing list. My passion lies in crafting an emersive UX through beautiful UI & interactions. My current toolset is coding these UIs in Sublime Text, Bootstrap, jQuery, Highcharts and D3.js.';
	return (
		<React.Fragment>
			<h1 className="section-heading">{title}</h1>
			<div className="sub-heading" dangerouslySetInnerHTML={{ __html: subHeadings }} />
		</React.Fragment>
	);
}
