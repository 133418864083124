import React from 'react';

function BlogPostCard({ imageId, title, createdAt, readingTime, subtitle, uniqueSlug, authorName }) {
	const imgURL = `https://cdn-images-1.medium.com/max/500/${imageId}`;
	const roundedReadingTime = Math.ceil(readingTime);
	const postURL = `https://medium.com/@${authorName}/${uniqueSlug}`;

	return (
		<div className="card">
			<a href={postURL} target="blank">
				<img className="card-img-top" src={imgURL} width="360" height="240" />
			</a>
			<div className="card-body">
				<a href={postURL} target="blank">
					<h5 className="card-title">{title}</h5>
				</a>
				<div className="stats">
					{createdAt}
					<div className="reading-time">
						<span className="clock" />
						{roundedReadingTime} min read
					</div>
				</div>
				<hr />
				<p className="card-text">{subtitle}</p>
				<a href={postURL} target="blank" className="float-right read-more">
					<span className="external-link" />
					Read full article
				</a>
			</div>
		</div>
	);
}

export default BlogPostCard;
