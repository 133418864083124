import React from 'react';
import Slider from 'react-slick';
import BlogPostCard from 'templates/BlogPostCard';

export default function SlickCarousel({ data }) {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		className: 'slides',
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			}
		]
	};

	return (
		<Slider {...settings}>
			{data.edges.map((item, index) => (
				<BlogPostCard
					key={index}
					imageId={item.node.virtuals.previewImage.imageId}
					title={item.node.title}
					createdAt={item.node.createdAt}
					readingTime={item.node.virtuals.readingTime}
					subtitle={item.node.virtuals.subtitle}
					uniqueSlug={item.node.uniqueSlug}
					authorName={item.node.author.name}
				/>
			))}
		</Slider>
	);
}
