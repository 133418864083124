import React from 'react';
import { animateScroll } from 'react-scroll';

export default function BacktoTop({ isVisible }) {
	const scrollTo = () => {
		animateScroll.scrollTo(0);
	};

	return (
		<button onClick={scrollTo} className={`back-to-top ${isVisible ? 'visible' : ''}`} title="Back to top">
			<span className="top-arrow" />
		</button>
	);
}
