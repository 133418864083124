import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import marked from 'marked';
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';
// import Typography from 'typography'
// import githubTheme from 'typography-theme-github'

// const typography = new Typography(githubTheme)
// typography.injectStyles('project-content')

export default class ProjectContent extends React.Component {
	state = {
		shouldShowCloseSuggestion: false
	};

	componentDidMount() {
		Prism.highlightAll();
		document.addEventListener('keydown', this.handleESCPress, false);
		setTimeout(() => {
			this.setState({
				shouldShowCloseSuggestion: true
			});
			setTimeout(() => {
				this.setState({
					shouldShowCloseSuggestion: false
				});
			}, 3000);
		}, 400);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleESCPress, false);
	}

	handleESCPress = event => {
		if (event.keyCode === 27) {
			return this.props.handleClose();
		}
	};

	render() {
		const { className, handleClose, url, homepageUrl } = this.props;
		const { shouldShowCloseSuggestion } = this.state;

		return (
			<StaticQuery
				query={graphql`
					query ReadMe {
						github {
							user(login: "SrikanthBandaru") {
								repositories(first: 100) {
									edges {
										node {
											name
											object(expression: "master:README.md") {
												... on GitHub_Blob {
													text
												}
											}
										}
									}
								}
							}
						}
					}
				`}
				render={data =>
					readmeParser(
						className,
						handleClose,
						data.github.user.repositories.edges.find(repo => repo.node.name === this.props.name),
						shouldShowCloseSuggestion,
						url,
						homepageUrl
					)
				}
			/>
		);
	}
}

function readmeParser(className, handleClose, data, shouldShowCloseSuggestion, url, homepageUrl) {
	if (data.node.object) {
		const readme = data.node.object.text;
		const html = marked(readme, { gfm: true });

		return (
			<div className={`project-content ${className}`}>
				<div className="container text-left">
					{shouldShowCloseSuggestion && (
						<div className="esc-notification mx-auto">
							Press <span className="button-name">ESC</span> to exit view
						</div>
					)}
					<a target="_blank" href={url}>
						<span className="mx-1 source-icon" />
						Source
					</a>
					{homepageUrl && (
						<a target="_blank" href={homepageUrl} className="float-right align-middle">
							<span className="mx-1 newtab-icon" />
							Visit demo
						</a>
					)}
					<div dangerouslySetInnerHTML={{ __html: html }} />
				</div>
				<button className="close img-replace" onClick={handleClose} />
			</div>
		);
	}
	return (
		<div className={`project-content ${className}`}>
			<div className="container text-left">There is no extra information for this repo at this time.</div>
			<button className="close img-replace" onClick={handleClose} />
		</div>
	);
}
