import React from 'react';
import ProjectContent from 'templates/ProjectContent';
import portfolioPlaceholder from 'assets/images/portfolio-placeholder.jpg';

export default class PortfolioCard extends React.Component {
	constructor(props) {
		super(props);
		const { nameWithOwner, logo } = props.item.node;
		this.state = {
			shouldShowProjectContent: false,
			shouldSlideDown: false,
			image:
				logo && logo.isBinary
					? `https://raw.githubusercontent.com/${nameWithOwner}/master/logo.jpg`
					: portfolioPlaceholder
		};
	}

	openProjectContent = () => {
		this.setState({
			shouldShowProjectContent: true
		});
		document.body.style.overflow = 'hidden';
	};

	closeProjectContent = () => {
		this.setState({
			shouldSlideDown: true
		});
		setTimeout(() => {
			this.setState({
				shouldShowProjectContent: false,
				shouldSlideDown: false
			});
		}, 350);
		document.body.style.overflow = '';
	};

	onImageError = () => {
		this.setState({
			image: portfolioPlaceholder
		});
	};

	render() {
		const { item } = this.props;
		const { image } = this.state;
		const dataGroups = item.node.repositoryTopics.edges.map(item => item.node.topic.name);

		return (
			<React.Fragment>
				<figure
					data-groups={dataGroups}
					className={`element-item m-0 ${dataGroups.join(' ')}`}
					onClick={this.openProjectContent}
				>
					<img
						src={image}
						onError={this.onImageError}
						alt="portfolio"
						style={{ width: '100%', height: '100%' }}
					/>
					<figcaption>
						<h4>{item.node.name}</h4>
						<p>{item.node.description}</p>
					</figcaption>
				</figure>
				{this.state.shouldShowProjectContent && (
					<ProjectContent
						handleClose={this.closeProjectContent}
						className={this.state.shouldSlideDown ? 'project-content-slide-down' : ''}
						name={item.node.name}
						url={item.node.url}
						homepageUrl={item.node.homepageUrl}
					/>
				)}
			</React.Fragment>
		);
	}
}
