import React from 'react';
import SocialMedia from 'components/Shared/SocialMedia';
import Shapes from './Shapes';
import me from 'assets/images/me.png';
import { animateScrollTo } from 'utils/animateScrollTo';
import jsonResumeSchema from 'constants/jsonResumeSchema'

export default function HeroSection() {
	return (
		<div className="hero-section" id="hero-section">
			<Shapes />
			<SocialMedia />
			<div className="container hero-text">
				<img src={me} width="170" height="170" alt="Srikanth Bandaru" />
				<span className="hero-hr" />
				<h1>Srikanth Bandaru</h1>
				<h2>
					A &nbsp;
					<span
						className="typewrite-text"
						data-period="2000"
						data-rotate='[ "dad.", "husband.", "programmer.", "learner." ]'
					/>
				</h2>
				<p className="about-me">
					Hi, my name is Srikanth, and I am a {jsonResumeSchema.basics.label}. I care deeply about the people I work with
					and bring empathy and strong communication to teams. I strive to learn and teach as much as I can.
				</p>
			</div>
			<a href="#skills" onClick={event => animateScrollTo(event)} className="scroll-down">
				Scroll
				<span className="arrow arrow-down" />
			</a>
		</div>
	);
}
