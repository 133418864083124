import React from 'react';
import { navItems } from 'constants/navItems';

const NavItems = ({ currentElement, sideNav, children }) => {
	const baseClass = sideNav ? 'sidenav-item' : 'nav-item';
	sideNav && !navItems.includes('home') && navItems.unshift('home');
	!sideNav && navItems.includes('home') && navItems.splice(0, 1);

	return (
		<ul
			className={sideNav ? 'side-nav m-auto d-xs-block d-lg-none condensed' : 'top-nav d-none d-lg-flex'}
			role="menu"
		>
			{navItems.map(item => {
				const navigationItem = `${item[0].toUpperCase()}${item.slice(1)}`;
				const navigationLink = `#${item === 'home' ? '' : item}`;

				return (
					<li key={item}>
						<a href={navigationLink} className={`${baseClass} ${currentElement === item ? 'active' : ''}`}>
							{sideNav && <span className={`mx-3 ${item}-icon`} />}
							{navigationItem}
						</a>
					</li>
				);
			})}
			{children}
		</ul>
	);
};

export default NavItems;
