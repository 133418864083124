const elementsOffset = {};

/**
 * This utility function allows to spy on profile elements and get their offset top & bottom
 * @return {Object.<string, object>} profile elements offset top & bottom
 */
export const getElementsToSpy = () => {
	const profileElements = document.getElementById('profile').children;
	for (var element of profileElements) {
		if (element.id !== 'hero-section') {
			elementsOffset[element.id] = {
				top: element.offsetTop,
				bottom: element.offsetTop + element.scrollHeight
			};
		}
	}

	elementsOffset.home = document.getElementById('hero-section').getBoundingClientRect();
	// console.log(elementsOffset);

	return elementsOffset;
};

/**
 * This utility function should be called after calling getElementsToSpy. As the user scrolls,
 * it caluclates and returns the current element user is looking at
 * @param {Number} scrollPositionY vertical scroll position
 * @return {String} current element the user is looking at
 */
export const getCurrentElement = scrollPositionY => {
	const navBarHeight = document.getElementById('header').offsetHeight;

	for (const key of Object.keys(elementsOffset)) {
		if (
			scrollPositionY + navBarHeight >= elementsOffset[key].top &&
			scrollPositionY + navBarHeight < elementsOffset[key].bottom
		) {
			return key;
		}
	}
	return 'home';
};
