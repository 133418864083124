import React from 'react';
import jsonResumeSchema from 'constants/jsonResumeSchema';
import Button from 'components/Shared/Button';
import Timeline from 'templates/Timeline';
import SectionHeading from 'templates/SectionHeading';
import FlipMove from 'react-flip-move';
import debounce from 'utils/debounce';

export default class Work extends React.Component {
	itemsToDisplay = 2;
	state = {
		itemsToDisplay: this.itemsToDisplay,
		viewMoreCount: jsonResumeSchema.work.length - this.itemsToDisplay
	};
	flipMove = React.createRef();

	componentDidMount() {
		this.setState({
			height: this.flipMove.current.clientHeight
		});
		return window.addEventListener('resize', debounce(this.updateDimensions, 16));
	}

	componentWillUnmount() {
		return window.removeEventListener('resize', debounce(this.updateDimensions, 16));
	}

	updateDimensions = () => {
		this.setState({
			height: '100%'
		});

		setTimeout(() => {
			// sending this to the call back queue to set the height in px and get the css transition effect
			this.setState({
				height: this.flipMove.current.scrollHeight
			});
		}, 10);
	};

	viewMoreButtonClick = () => {
		const { itemsToDisplay, viewMoreCount } = this.state;
		this.setState({
			itemsToDisplay: itemsToDisplay + 2,
			viewMoreCount: viewMoreCount - 2
		});
	};

	componentDidUpdate = () => {
		if (this.state.height !== '100%' && this.state.height !== this.flipMove.current.scrollHeight) {
			this.setState({
				height: this.flipMove.current.scrollHeight
			});
		}
	};

	render() {
		const { viewMoreCount, height } = this.state;

		return (
			<section id="work" className="container py-5">
				<SectionHeading
					title="Work History"
					subHeading="I care deeply about the people I work with. I bring empathy and strong communication to teams, and strive to learn and teach as much as I can. You can also check my <a class='root-page-link' href='/cv/' target='_blank'>CV here</a>."
				/>
				<div
					className="main-timeline"
					ref={this.flipMove}
					style={{ height: height, transition: 'height 650ms cubic-bezier(0.4, 0, 0.2, 1) 0s' }}
				>
					<FlipMove typeName={null}>
						{jsonResumeSchema.work.slice(0, this.state.itemsToDisplay).map((item, index) => (
							<Timeline key={index} {...item} />
						))}
					</FlipMove>
				</div>
				{viewMoreCount > 0 && (
					<Button onClick={this.viewMoreButtonClick} className="float-right">
						View {viewMoreCount} more
					</Button>
				)}
			</section>
		);
	}
}
