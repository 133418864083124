import React from 'react';
import SectionHeading from 'templates/SectionHeading';
import Button from 'components/Shared/Button';
import encode from 'utils/encode';
import SnackbarContentWrapper from 'components/Shared/SnackbarContentWrapper';
import Snackbar from '@material-ui/core/Snackbar';

class Contact extends React.Component {
	state = {
		fullName: '',
		email: '',
		message: '',
		isSubmitSuccess: null,
		shouldShowSnackbar: false
	};

	handleFormSubmit = event => {
		event.preventDefault();
		const { fullName, email, message } = this.state;
		const isContactFormValid = fullName && email && message;

		if (isContactFormValid) {
			const form = event.target;
			fetch('/', {
				method: 'POST',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				body: encode({
					'form-name': form.getAttribute('name'),
					...this.state
				})
			})
				.then(() =>
					this.setState({
						isSubmitSuccess: true,
						shouldShowSnackbar: true,
						fullName: '',
						email: '',
						message: ''
					})
				)
				.catch(() =>
					this.setState({
						isSubmitSuccess: false,
						shouldShowSnackbar: true
					})
				);
		}
	};

	handleInputChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleSnackbarClose = () => {
		this.setState({
			shouldShowSnackbar: false
		});
	};

	render() {
		const renderInput = (name, label, type) => (
			<>
				<input
					type={type}
					className="form-control"
					name={name}
					placeholder=" "
					value={this.state[name]}
					onChange={this.handleInputChange}
				/>
				<label className="floating-label" for={name}>
					{label}
				</label>
				<div className="underline" />
			</>
		);

		return (
			<section id="contact" className="container py-5">
				<SectionHeading
					title="Contact"
					subHeading="Say Hello!! If we ever meet in person, your drink is on me! "
				/>
				<div className="contact-form m-auto">
					<form
						className="row"
						name="contact"
						onSubmit={this.handleFormSubmit}
						method="post"
						data-netlify="true"
						data-netlify-honeypot="bot-field"
						autocomplete="off"
					>
						<input type="hidden" name="bot-field" />
						<div className="form-group col-md-6">{renderInput('fullName', 'Full Name', 'text')}</div>
						<div className="form-group col-md-6">{renderInput('email', 'Email address', 'email')}</div>
						<div className="form-group col-md-12">{renderInput('message', 'Your Message', 'text')}</div>
						<div className="col-md-12 text-center mt-4">
							<Button>Submit</Button>
						</div>
						<Snackbar
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right'
							}}
							open={this.state.shouldShowSnackbar}
							autoHideDuration={6000}
							onClose={this.handleSnackbarClose}
						>
							<SnackbarContentWrapper
								onClose={this.handleSnackbarClose}
								variant={this.state.isSubmitSuccess ? 'success' : 'error'}
								message={
									this.state.isSubmitSuccess
										? 'Success! I got your message!!!'
										: 'Oops! Message could not be sent!!'
								}
							/>
						</Snackbar>
					</form>
				</div>
			</section>
		);
	}
}

export default Contact;
