import React from 'react';

export default function Button({ to, onClick, children, newTab, className }) {
	return (
		<a href={to} onClick={onClick} target={newTab ? 'blank' : ''}>
			<button className={`${className} button`}>
				{children}
				<span className="button-arrow" />
			</button>
		</a>
	);
}
