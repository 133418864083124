import React from 'react';
import SectionHeading from 'templates/SectionHeading';
import SlickCarousel from 'templates/SlickCarousel';
import { StaticQuery, graphql } from 'gatsby';

export default class Blog extends React.Component {
	componentDidMount() {
		window.addEventListener('touchstart', this.touchStart);
		window.addEventListener('touchmove', this.preventTouch, { passive: false });
	}

	componentWillUnmount() {
		window.removeEventListener('touchstart', this.touchStart);
		window.removeEventListener('touchmove', this.preventTouch, { passive: false });
	}

	touchStart(event) {
		this.firstClientX = event.touches[0].clientX;
		this.firstClientY = event.touches[0].clientY;
	}

	preventTouch(event) {
		const minValue = 5; // threshold
		const clientX = event.touches[0].clientX - this.firstClientX;

		// Vertical scrolling does not work when users start swiping horizontally
		if (Math.abs(clientX) > minValue) {
			event.preventDefault();
			event.returnValue = false;
			return false;
		}
	}

	render() {
		return (
			<StaticQuery
				query={graphql`
					query StoriesQuery {
						allMediumPost(sort: { fields: [createdAt], order: DESC }) {
							edges {
								node {
									id
									title
									virtuals {
										subtitle
										previewImage {
											imageId
										}
										readingTime
									}
									uniqueSlug
									createdAt(formatString: "MMMM DD, YYYY")
									author {
										name
									}
								}
							}
						}
					}
				`}
				render={data => (
					<section id="blog" className="py-5">
						<div className="container">
							<SectionHeading
								title="Blog"
								subHeading="I learn from the community and love to share back what I have learned. I started regularly writing about everything from Front End development to ServerSide JavaScript."
							/>
							<SlickCarousel data={data.allMediumPost} />
						</div>
					</section>
				)}
			/>
		);
	}
}
