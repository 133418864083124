import * as d3 from 'd3';
// import { flare } from '../constants/flare';
import { skills as flare } from '../constants/skills';

export default function skillsDistributionChart() {
	const width = window.innerWidth;
	const height = window.innerHeight;
	const maxRadius = Math.min(width, height) / 2 - 5;

	const formatNumber = d3.format(',d');

	const x = d3
		.scaleLinear()
		.range([0, 2 * Math.PI])
		.clamp(true);

	const y = d3.scaleSqrt().range([maxRadius * 0.1, maxRadius]);

	const color = d3.scaleOrdinal(d3.schemeCategory20c);

	const partition = d3.partition();

	const arc = d3
		.arc()
		.startAngle(d => x(d.x0))
		.endAngle(d => x(d.x1))
		.innerRadius(d => Math.max(0, y(d.y0)))
		.outerRadius(d => Math.max(0, y(d.y1)));

	const middleArcLine = d => {
		const halfPi = Math.PI / 2;
		const angles = [x(d.x0) - halfPi, x(d.x1) - halfPi];
		const r = Math.max(0, (y(d.y0) + y(d.y1)) / 2);

		const middleAngle = (angles[1] + angles[0]) / 2;
		const invertDirection = middleAngle > 0 && middleAngle < Math.PI; // On lower quadrants write text ccw
		if (invertDirection) {
			angles.reverse();
		}

		const path = d3.path();
		path.arc(0, 0, r, angles[0], angles[1], invertDirection);
		return path.toString();
	};

	const textFits = d => {
		const CHAR_SPACE = 16;

		const deltaAngle = x(d.x1) - x(d.x0);
		const r = Math.max(0, (y(d.y0) + y(d.y1)) / 2);
		const perimeter = r * deltaAngle;

		return d.data.name.length * CHAR_SPACE < perimeter;
	};

	const chartHeight = 410;
	const svg = d3
		.select('#skills-distribution-chart')
		.append('svg')
		.attr('width', '100%')
		.attr('height', chartHeight)
		// .attr('viewBox', `-500 -500 1000 1000`)
		.style('font-size', '1rem')
		.on('click', () => focusOn()); // Reset zoom on canvas click

	var root = flare;

	root = d3.hierarchy(root);
	root.sum(d => d.size);

	const slice = svg.selectAll('g.slice').data(partition(root).descendants());

	slice.exit().remove();

	const newSlice = slice
		.enter()
		.append('g')
		.attr('class', 'slice')
		.on('click', d => {
			d3.event.stopPropagation();
			focusOn(d);
		});

	newSlice.append('title').text(d => d.data.name);

	newSlice
		.append('path')
		.attr('class', 'main-arc')
		.style('fill', d => color((d.children ? d : d.parent).data.name))
		.attr('d', arc)
		.on('mouseover', mouseOver)
		.on('mouseout', mouseOut);

	function mouseOver(d) {
		d3.selectAll('path')
			.filter(function(node) {
				return [d].indexOf(node) >= 0;
			})
			.style('opacity', 0.8);
	}

	function mouseOut(d) {
		var sequenceArray = d.ancestors().reverse();
		d3.selectAll('path')
			.filter(function(node) {
				return sequenceArray.indexOf(node) >= 0;
			})
			.style('opacity', 1);
	}

	newSlice
		.append('path')
		.attr('class', 'hidden-arc')
		.attr('id', (_, i) => `hiddenArc${i}`)
		.attr('d', middleArcLine);

	const text = newSlice.append('text').attr('display', d => (textFits(d) ? null : 'none'));

	// Add white contour
	const fontSize = width < 768 ? '1rem' : '2rem';
	const strokeWidth = width < 768 ? 3 : 6;

	text.append('textPath')
		.attr('startOffset', '50%')
		.attr('xlink:href', (_, i) => `#hiddenArc${i}`)
		.text(d => d.data.name)
		.style('fill', 'none')
		.style('stroke', '#fff')
		.style('stroke-width', strokeWidth)
		.style('font-size', fontSize)
		.style('stroke-linejoin', 'round');

	text.append('textPath')
		.attr('startOffset', '50%')
		.attr('xlink:href', (_, i) => `#hiddenArc${i}`)
		.style('font-size', fontSize)
		.text(d => d.data.name);
	// 	}
	// );

	function focusOn(d = { x0: 0, x1: 1, y0: 0, y1: 1 }) {
		// Reset to top-level if no data point specified

		const transition = svg
			.transition()
			.duration(650)
			.tween('scale', () => {
				const xd = d3.interpolate(x.domain(), [d.x0, d.x1]),
					yd = d3.interpolate(y.domain(), [d.y0, 1]);
				return t => {
					x.domain(xd(t));
					y.domain(yd(t));
				};
			});

		transition.selectAll('path.main-arc').attrTween('d', d => () => arc(d));

		transition.selectAll('path.hidden-arc').attrTween('d', d => () => middleArcLine(d));

		transition.selectAll('text').attrTween('display', d => () => (textFits(d) ? null : 'none'));

		// moveStackToFront(d);

		// function moveStackToFront(elD) {
		// 	svg.selectAll('.slice')
		// 		.filter(d => d === elD)
		// 		.each(function(d) {
		// 			this.parentNode.appendChild(this);
		// 			if (d.parent) {
		// 				moveStackToFront(d.parent);
		// 			}
		// 		});
		// }
	}

	function autosize(svg) {
		document.body.appendChild(svg);
		const box = svg.getBBox();
		document.body.removeChild(svg);
		svg.setAttribute('viewBox', `${box.x} ${box.y} ${box.width} ${box.height}`);
		document.getElementById('skills-distribution-chart').appendChild(svg);
		return svg;
	}

	return autosize(svg.node());
}
