import React from 'react';
// import PropTypes from "prop-types"
import TypewriteText from 'utils/typewriteText';
import debounce from 'utils/debounce';
import { getElementsToSpy, getCurrentElement } from 'utils/highlightMenuItem';
import { SideNav, TopNav } from './Navigation';
import { connect } from 'react-redux';
import Footer from 'components/Footer';
import ReactResizeDetector from 'react-resize-detector';
import BacktoTop from './Shared/BacktoTop';
import './layout.scss';
import SEO from './SEO';

class Layout extends React.PureComponent {
	state = {
		currentElement: 'home',
		shouldFixHeader: false,
		scrollDirection: '',
		shouldShowBackToTop: false
	};
	counter = 0;
	// oldScroll;

	componentDidMount() {
		let elements = document.getElementsByClassName('typewrite-text');
		for (let i = 0; i < elements.length; i++) {
			const toRotate = elements[i].getAttribute('data-rotate');
			const period = elements[i].getAttribute('data-period');
			if (toRotate) {
				new TypewriteText(elements[i], JSON.parse(toRotate), period);
			}
		}

		window.addEventListener('scroll', debounce(this.handleScroll, 8));
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', debounce(this.handleScroll, 8));
	}

	handleScroll = () => {
		const scrollPositionY = window.scrollY;
		const shouldFixHeader = window.innerWidth >= 992 && scrollPositionY > 0;
		// const scrollDirection = { true: 'up', false: 'down' }[oldScroll > scrollPositionY];

		if (this.state.shouldFixHeader !== shouldFixHeader) {
			this.setState({
				shouldFixHeader
			});
		}
		// if (scrollDirection === 'down' && shouldFixHeader !== this.state.shouldFixHeader) {
		// 	this.setState({
		// 		shouldFixHeader: scrollPositionY > window.innerHeight / 2,
		// 		scrollDirection
		// 	});
		// }

		// if (scrollDirection === 'up' && shouldFixHeader !== this.state.shouldFixHeader) {
		// 	this.setState({
		// 		scrollDirection
		// 	});

		// 	setTimeout(() => {
		// 		this.setState({
		// 			shouldFixHeader: scrollPositionY > window.innerHeight
		// 		});
		// 	}, 650);
		// }

		// this.oldScroll = scrollPositionY;

		const currentElement = getCurrentElement(scrollPositionY);
		currentElement !== this.state.currentElement && this.setState({ currentElement });
		const shouldShowBackToTop = currentElement !== 'home';

		if (this.state.shouldShowBackToTop !== shouldShowBackToTop) {
			this.setState({
				shouldShowBackToTop
			});
		}
	};

	onResize = () => {
		getElementsToSpy();
		this.getCurrentElement();
	};

	getCurrentElement = () => {
		const currentElement = getCurrentElement(window.scrollY);
		currentElement !== this.state.currentElement && this.setState({ currentElement });
	};

	render() {
		const { isSideNavOpen } = this.props;
		// console.log(++this.counter);
		// console.log(this.state.currentElement);

		return (
			<>
				<SEO />
				<div className={`content ${isSideNavOpen ? 'content-sideNavv' : ''}`}>
					<TopNav
						isSideNavOpen={this.props.isSideNavOpen}
						currentElement={this.state.currentElement}
						toggleSideNav={this.props.toggleSideNav}
						shouldFixHeader={this.state.shouldFixHeader}
					/>
					<SideNav
						isSideNavOpen={this.props.isSideNavOpen}
						currentElement={this.state.currentElement}
						toggleSideNav={this.props.toggleSideNav}
					/>
					<ReactResizeDetector
						handleWidth
						handleHeight
						onResize={this.onResize}
						refreshMode="debounce"
						refreshRate={50}
					>
						<main>{this.props.children}</main>
					</ReactResizeDetector>
					<Footer />
					<BacktoTop isVisible={this.state.shouldShowBackToTop} />
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ count, isSideNavOpen }) => {
	return { count, isSideNavOpen };
};

const mapDispatchToProps = dispatch => {
	return { toggleSideNav: () => dispatch({ type: `TOGGLE_SIDENAV` }) };
};

const DefaultLayout = connect(
	mapStateToProps,
	mapDispatchToProps
)(Layout);

export default DefaultLayout;
