/**
 * This utility function sorts the given array by frequency of occurrences and returns a sliced array with unique & sorted items
 * @param {Array} list List that requires sorting
 * @param {Number} cutOff Length of the array that needs to be returned
 * @return {Array} A sliced array with unique and sorted items by frequency of occurrences
 */
const sortByFrequency = (list, cutOff) => {
	let frequencies = {};
	list.map(item => {
		// eslint-disable-line array-callback-return
		frequencies[item] = frequencies[item] || 0;
		frequencies[item]++;
	});
	let uniqueItemsList = Object.keys(frequencies);

	return uniqueItemsList.sort((a, b) => frequencies[b] - frequencies[a]).slice(0, cutOff);
};

export default sortByFrequency;
