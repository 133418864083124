import React from 'react';
import Layout from 'components/layout';
import HeroSection from 'components/HeroSection';
import Skills from 'components/Skills';
import Portfolio from 'components/Portfolio';
import Work from 'components/Work';
import Blog from 'components/Blog';
import Contact from 'components/Contact';

const Home = () => {
	console.log(
		'%c Site developed with - ღ - by Srikanth Bandaru',
		'background: #2c3e50; padding: 5px; font-size: 13px; color: #fff;'
	);

	return (
		<Layout>
			<div id="profile">
				<HeroSection />
				<Skills />
				<Portfolio />
				<Work />
				<Blog />
				<Contact />
			</div>
		</Layout>
	);
};

export default Home;
