import React from 'react';
import jsonResumeSchema from 'constants/jsonResumeSchema';

export default function SocialMedia({ alignHorizontally }) {
	return (
		<div
			className={`social-media-icon-bar ${
				alignHorizontally
					? 'position-bottom d-flex justify-content-center'
					: 'd-none d-lg-flex position-right list-group'
			}`}
		>
			{jsonResumeSchema.basics.profiles.map((profile, index) => {
				return (
					<a
						key={index}
						href={profile.url}
						target="blank"
						className={`${alignHorizontally ? 'mx-3' : 'my-3'} ${profile.network.toLowerCase()}`}
					>
						{profile.network.toLowerCase()}
					</a>
				);
			})}
		</div>
	);
}
