import Shuffle from 'shufflejs';

var iso;

export function initializeShuffle() {
	var element = document.querySelector('.grid');

	iso = new Shuffle(element, {
		itemSelector: '.element-item',
		speed: 650,
		delimiter: ',',
		isCentered: true
	});
}

export function filterItems(filter, itemsToDisplay) {
	let i = 0;

	iso.filter(itemElem => {
		if (itemElem.className.includes(filter)) {
			i++;
			return i <= itemsToDisplay;
		}
		return null;
	});

	return i;
}
