import React from 'react';
import Parallax from 'assets/lib/parallax.min.js';
import SVG from './SVG';
import './shapes.scss';

export default class Shapes extends React.Component {
	componentDidMount() {
		var scene = document.getElementById('scene');
		new Parallax(scene);
	}

	render() {
		const colors = {
			'color-blue': 'rgb(91, 156, 190)',
			'color-dark-bluish-gray': 'rgb(1, 70, 107)',
			'color-light-bluish-gray-shade2': 'rgb(39, 50, 56)',
			'color-bright-green': 'rgb(56, 193, 114)',
			'color-bright-purple': 'rgb(149, 97, 226)',
			'color-bright-red': 'rgb(191, 56, 26)',
			'color-bright-orange': 'rgb(224, 118, 40)',
			'color-bright-yellow': 'rgb(255, 255, 0)',
			'color-bright-blue': 'rgb(39, 121, 189)'
		};

		return (
			<div className="shapes">
				<SVG
					icon="circle"
					width="1em"
					className="d-none d-md-block"
					fill={colors['color-light-bluish-gray-shade2']}
					left="4%"
					top="20%"
				/>
				<SVG
					icon="circle"
					className="d-none d-lg-block"
					width="2em"
					fill={colors['color-light-bluish-gray-shade2']}
					left="50%"
					top="60%"
				/>
				<SVG
					icon="box"
					className="d-none d-md-block"
					width="1em"
					fill={colors['color-blue']}
					left="95%"
					top="90%"
				/>
				<SVG
					className="d-none d-lg-block"
					icon="upDown"
					width="4em"
					fill={colors['color-dark-bluish-gray']}
					left="40%"
					top="80%"
				/>
				<SVG
					icon="triangle"
					width="1.3em"
					className="d-none d-md-block"
					stroke={colors['color-dark-bluish-gray']}
					left="25%"
					top="5%"
				/>
				<SVG
					icon="circle"
					width="10.5em"
					className="d-none d-md-block"
					fill={colors['color-bright-green']}
					left="95%"
					top="5%"
				/>
				<SVG icon="box" className="" width="10.5em" fill={colors['color-bright-purple']} left="5%" top="90%" />
				<SVG
					icon="box"
					width="1em"
					className="d-none d-lg-block"
					fill={colors['color-light-bluish-gray-shade2']}
					left="10%"
					top="10%"
				/>
				<SVG icon="box" width="2em" fill={colors['color-light-bluish-gray-shade2']} left="40%" top="30%" />
				<SVG
					icon="hexa"
					width="2.6em"
					className="d-none d-md-block"
					stroke={colors['color-dark-bluish-gray']}
					left="10%"
					top="50%"
				/>
				<SVG icon="hexa" width="1.3em" stroke={colors['color-dark-bluish-gray']} left="80%" top="70%" />

				<div id="scene">
					<SVG
						icon="circle"
						width="1em"
						dataDepth="0.5"
						fill={colors['color-light-bluish-gray-shade2']}
						left="75%"
						top="10%"
					/>

					<SVG
						className="d-none d-lg-block"
						icon="arrowUp"
						width="2.6em"
						dataDepth="0.4"
						fill={colors['color-bright-blue']}
						left="80%"
						top="10%"
					/>

					<SVG
						className="d-none d-xl-block"
						icon="triangle"
						width="2em"
						dataDepth="0.2"
						stroke={colors['color-bright-yellow']}
						left="90%"
						top="50%"
					/>
					<SVG
						className="d-none d-xl-block"
						icon="circle"
						width="2.6em"
						dataDepth="0.5"
						fill={colors['color-dark-bluish-gray']}
						left="70%"
						top="90%"
					/>

					<SVG
						icon="triangle"
						width="2.6em"
						dataDepth="0.3"
						stroke={colors['color-bright-blue']}
						left="30%"
						top="65%"
					/>
					<SVG
						className="d-none d-xl-block"
						icon="upDown"
						width="1.3em"
						dataDepth="0.5"
						fill={colors['color-light-bluish-gray-shade2']}
						left="45%"
						top="10%"
					/>

					<SVG
						icon="triangle"
						className="d-none d-md-block"
						width="4em"
						dataDepth="0.1"
						stroke={colors['color-bright-orange']}
						left="10%"
						top="20%"
					/>
					<SVG
						icon="hexa"
						width="8em"
						dataDepth="0.2"
						stroke={colors['color-bright-red']}
						left="60%"
						top="70%"
					/>
					<SVG
						icon="box"
						width="1em"
						dataDepth="0.2"
						fill={colors['color-dark-bluish-gray']}
						left="60%"
						top="15%"
					/>
				</div>
			</div>
		);
	}
}
