import React from 'react';
import Button from 'components/Shared/Button';
import sortByFrequency from 'utils/sortByFrequency';
import { initializeShuffle, filterItems } from 'utils/shuffle';
import SectionHeading from 'templates/SectionHeading';
import PortfolioCard from 'templates/PortfolioCard';
import { StaticQuery, graphql } from 'gatsby';

class Portfolio extends React.PureComponent {
	constructor(props) {
		super(props);
		const itemsToDisplay = typeof window !== 'undefined' && window.innerWidth < 992 ? 3 : 6;
		const { edges: repositories } = props.github.repositories;
		const { edges: pinnedItems } = props.github.user.pinnedItems;
		const portfolioProjects = repositories.filter(item => !item.node.isPrivate);
		const portfolioProjectsLength = portfolioProjects.length;

		pinnedItems.reverse();
		portfolioProjects.sort((a, b) => {
			return (
				pinnedItems.findIndex(obj => obj.node.name === b.node.name) -
				pinnedItems.findIndex(obj => obj.node.name === a.node.name)
			);
		});

		this.state = {
			portfolioProjects,
			itemsToDisplay,
			activeFilter: 'all',
			viewMoreCount: portfolioProjectsLength - itemsToDisplay,
			portfolioProjectsLength
		};
	}

	componentDidMount() {
		initializeShuffle();
		filterItems('element-item', this.state.itemsToDisplay);
	}

	filterClickHandler = event => {
		const filterValue = event.target.getAttribute('data-filter');
		const totalElements = filterItems(filterValue, this.state.itemsToDisplay);
		this.setState({
			activeFilter: event.target.id,
			viewMoreCount: totalElements - this.state.itemsToDisplay
		});
	};

	viewMoreButtonClick = () => {
		this.setState({
			itemsToDisplay: this.state.itemsToDisplay + 3,
			viewMoreCount: this.state.portfolioProjectsLength - this.state.itemsToDisplay - 3
		});
		setTimeout(() => {
			filterItems('element-item', this.state.itemsToDisplay);
		}, 10);
	};

	renderFilter = portfolioProjects => {
		const projectCategories = portfolioProjects.map(project =>
			project.node.repositoryTopics.edges.map(item => item.node.topic.name)
		);
		const flatProjectCategories = [].concat.apply([], projectCategories);
		const sortedFilterCategories = sortByFrequency(flatProjectCategories);
		const filterCategories = sortedFilterCategories.splice(0, 3);
		const filtersToHide = sortedFilterCategories.filter(element => filterCategories.indexOf(element) === -1);

		filterCategories.unshift('all');
		filterCategories.push('other');

		return (
			<div className="portfolio-filter-buttons">
				{filterCategories.map((filter, index) => {
					const dataFilter = { all: 'element-item', other: filtersToHide }[filter] || filter;
					return (
						<div
							id={filter}
							key={index}
							data-filter={dataFilter}
							className={`filter ${this.state.activeFilter === filter ? 'active' : ''}`}
							onClick={event => this.filterClickHandler(event, filterCategories)}
						>
							{filter.toUpperCase()}
						</div>
					);
				})}
			</div>
		);
	};

	renderViewMoreLink = () => {
		const { viewMoreCount } = this.state;
		if (viewMoreCount > 0) {
			return (
				<Button onClick={this.viewMoreButtonClick} className="float-right">
					View {viewMoreCount} more
				</Button>
			);
		}
	};

	render() {
		const { portfolioProjects } = this.state;

		return (
			<section id="portfolio" className="py-5">
				<div className="container">
					<SectionHeading
						title="Portfolio"
						subHeading="I enjoy bringing ideas to life and strive to build great applications. I also contribute to Open Source in my spare time. Take a look at some of my recent projects. You can also check my <a class='root-page-link' href='https://github.com/srikanthbandaru' target='_blank'>GitHub here</a>."
					/>
					<div className="portfolio-items text-center">
						{this.renderFilter(portfolioProjects)}
						<div className="grid">
							{portfolioProjects.map((item, key) => (
								<PortfolioCard item={item} key={key} imageURL={item.imageURL} />
							))}
						</div>
					</div>
					{this.renderViewMoreLink()}
				</div>
			</section>
		);
	}
}

export default () => (
	<StaticQuery
		query={graphql`
			query RepositoriesQuery {
				github {
					user(login: "SrikanthBandaru") {
						id
						email
						isHireable
						name
						repositoriesContributedTo(first: 100) {
							edges {
								node {
									name
									url
								}
							}
						}
						gists(first: 100) {
							edges {
								node {
									name
								}
							}
						}
						pinnedItems(first: 100, types: [REPOSITORY, GIST]) {
							totalCount
							edges {
								node {
									... on GitHub_Repository {
										name
									}
								}
							}
						}
					}
					repositories: search(
						query: "user:SrikanthBandaru fork:true archived:false"
						type: REPOSITORY
						first: 100
					) {
						repositoryCount
						edges {
							node {
								... on GitHub_Repository {
									nameWithOwner
									name
									logo: object(expression: "master:logo.jpg") {
										... on GitHub_Blob {
											isBinary
										}
									}
									isArchived
									isPrivate
									shortDescriptionHTML
									description
									descriptionHTML
									repositoryTopics(first: 10) {
										edges {
											node {
												topic {
													name
												}
											}
										}
									}
									homepageUrl
									url
								}
							}
						}
					}
				}
			}
		`}
		render={data => <Portfolio github={data.github} />}
	/>
);
