import React from 'react';
import moment from 'moment';
import bayer from 'assets/images/logos/bayer-logo.svg';
import cognizant from 'assets/images/logos/cognizant-logo.svg';
import infoDrive from 'assets/images/logos/infoDrive-logo.png';
import colorLayers from 'assets/images/logos/colorLayers-logo.png';
import ofi from 'assets/images/logos/ofi-logo.png';
import paceUniversity from 'assets/images/logos/paceUniversity-logo.png';

export default class Timeline extends React.Component {
	render() {
		const logo = {
			bayer: bayer,
			cognizant: cognizant,
			infoDrive: infoDrive,
			colorLayers: colorLayers,
			ofi: ofi,
			paceUniversity: paceUniversity
		}[this.props.shortName];

		return (
			<div className="timeline">
				<span className="timeline-icon" />
				<div className="timeline-content">
					<h3 className="title">{this.props.position}</h3>
					<p className="description">{this.props.summary}</p>
				</div>
				<div className="icon">
					<i className={this.props.shortName}>
						<img src={logo} alt={`${this.props.shortName}-logo`} />
					</i>
					<span className="year">{moment(this.props.startDate, 'MM/DD/YYYY').format('MMM YYYY')}</span>
				</div>
			</div>
		);
	}
}
