import React from 'react';
import NavItems from 'components/Shared/NavItems';
import HamburgerButton from 'components/Shared/HamburgerButton';
import { animateScrollTo } from 'utils/animateScrollTo';

class TopNav extends React.PureComponent {
	handleTogglerClick = event => {
		event.preventDefault();
		this.props.toggleSideNav();
	};

	render() {
		const { isSideNavOpen, currentElement } = this.props;
		const slideStyles = `${this.props.shouldFixHeader ? 'header-top--inset' : ''}`;

		return (
			<header id="header" className={`header-top ${slideStyles}`}>
				<nav className="navbar container" onClick={event => animateScrollTo(event)}>
					<a className="navbar-brand logo-mug" href="#" /> {/* eslint-disable-line */}
					<div className="float-right">
						<HamburgerButton isSideNavOpen={isSideNavOpen} handleTogglerClick={this.handleTogglerClick} />
						<NavItems currentElement={currentElement} isSideNavOpen={isSideNavOpen} />
					</div>
				</nav>
			</header>
		);
	}
}

export default TopNav;
