import React from 'react';
import SocialMedia from 'components/Shared/SocialMedia';

export default function Footer() {
	return (
		<footer className="pb-5">
			{/* <hr /> */}
			<div className="container text-center" style={{ color: 'rgb(119, 109, 127)', fontSize: '1rem' }}>
				<SocialMedia alignHorizontally />
				All content © Srikanth Bandaru
			</div>
		</footer>
	);
}
