/**
 * This utility function allows to animate scroll to a section.
 * @param {object} event Typically the event captured after onClick
 */

import { animateScroll } from 'react-scroll';

export const animateScrollTo = event => {
	event.preventDefault();
	if (event.target.href) {
		const link = event.target.href.split('#')[1];
		const targetElementOffSetTop = link && document.getElementById(link).getBoundingClientRect().top;
		const scrollTo = link === '' ? 0 : targetElementOffSetTop - document.body.getBoundingClientRect().top;
		// const navBarHeight = document.getElementById('header').offsetHeight;
		const navBarHeight = window.innerWidth > 992 ? 91 : 0;

		animateScroll.scrollTo(scrollTo - navBarHeight);
	}
};
